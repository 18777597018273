<template>
  <div class="container">
    <div class="pageDrawer pageOpen">
      <div id="pageHeader">
        <div class="container max-800">
          <div id="infoHolder">
            <i class="tio-help-outlined"> </i>
            Terms and Conditions
          </div>
        </div>
        <div id="closeHolder">
          <img
            src="@/assets/img/close.svg"
            id="closePage"
            @click="closePage()"
          />
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="container text-start mt-5 pt-3 max-800">
        <article>
          <h1>Terms and Conditions</h1>
          <p class="fs-6 fst-italic fw-lighter mb-3">
            Last amended: 6 May 2021
          </p>
          <ul class="no-bullets">
            <li>
              <h2>General Information</h2>
              <ol>
                <li>
                  These Terms and Conditions (“T&amp;Cs”) apply to the usage by
                  You of the Games (as defined below), the related enabling
                  Internet, mobile or other platforms through the website
                  scgames.io (“Website”) and other related URLs belonging to or
                  licensed to Scgames.
                </li>
              </ol>
            </li>
            <li id="obligations">
              <h2>Your Obligations as a User</h2>
              <ol>
                <li class="base-doc__item base-doc__h6-marker">
                  <h6 class="base-doc__h6 base-doc__inline">
                    You hereby represent and warrant that:
                  </h6>
                  <ol>
                    <li>
                      You have read these T&amp;Cs and other documents supplied
                      to You in connection with use of the Website and the Games
                      and You understand and agree that Your use of the Website
                      and Games will be governed by these T&amp;Cs as amended
                      from time to time.
                    </li>
                    <li>
                      You are over 18 years old or such higher minimum legal age
                      as stipulated in the laws of jurisdiction applicable to
                      You and, under the laws applicable to You, You are allowed
                      to participate in the Games offered on the Website.
                    </li>
                    <li>
                      You will use the Website and Your Member Account solely
                      and exclusively for the purpose of Your participation in
                      the Games and not for any financial or other reason, Your
                      participation in the Games will be strictly in Your
                      personal non-professional capacity for recreational and
                      entertainment reasons only.
                    </li>
                    <li>
                      You possess the sophistication, expertise and knowledge
                      necessary to make informed decisions in respect of bets,
                      stakes and wages, as well as use of and play the Games,
                      and you will not rely on any communication or statement
                      (written or verbal) by Scgames as financial, game or
                      investment advice or as a recommendation to enter into and
                      play any of the available Games.
                    </li>
                    <li>
                      You fully understand that it is entirely and solely Your
                      responsibility to enquire and ensure that You do not
                      breach laws applicable to You by participating in the
                      Games.
                    </li>
                    <li>
                      You register on Your behalf, and You participate in the
                      Games on Your own behalf and not on behalf of any other
                      person.
                    </li>
                    <li>
                      You are not a resident of (or in) a Restricted
                      Jurisdiction or any other jurisdiction where Your
                      participation or use of the Website or Games would be in
                      conflict with any applicable law or any other rules,
                      including those relating to online gaming. You may not
                      access or use the Website, app/s and Games from these
                      jurisdictions.
                    </li>
                    <li>
                      All information that You provide to Scgames during the
                      term of this Agreement is true, accurate, complete and
                      correct, and that You shall immediately notify Scgames of
                      any changes of such information.
                    </li>
                    <li>
                      You are solely responsible for reporting and accounting
                      for any taxes applicable to You under relevant laws for
                      Your participation in Games including any winnings that
                      You receive from Scgames.
                    </li>
                    <li>
                      You understand that by participating in the Games You take
                      the risk of losing Your funds.
                    </li>
                    <li>
                      You are and shall not be involved in any fraudulent,
                      collusive, fixing or other unlawful activity in relation
                      to Your or third parties’ participation in any of the
                      Games and shall not use any software-assisted methods or
                      techniques or hardware devices in connection with Your
                      participation in any of the Games. Scgames hereby reserves
                      the right to invalidate or terminate Your participation in
                      a Game in the event of such behaviour.
                    </li>
                    <li>
                      Execution by you of this Agreement and entering into each
                      Game session through the Website will not violate any law,
                      regulations or rule applicable to You.
                    </li>
                    <li>
                      The computer software that we make available to You is
                      owned by Scgames, its Affiliates or other third parties
                      and protected by copyright and other intellectual property
                      rights. You may only use the software for Your own
                      personal, recreational use in accordance with all rules,
                      terms and conditions as set out in these T&amp;Cs and in
                      accordance with all applicable laws, rules and
                      regulations.
                    </li>
                    <li>
                      Games played on or through the Website should be played in
                      the same manner as games played in any other setting. You
                      shall be courteous to other Users and representatives of
                      Scgames and shall avoid rude or obscene comments,
                      including in chat rooms.
                    </li>
                    <li>
                      You will take legal responsibility and bear liability for
                      compliance with the legislation applicable to You and
                      confirm that a bank or payment service provider who is
                      processing any of Your deposit or withdrawal transactions
                      shall not be liable for any violation of the laws and
                      legislation applicable to You.
                    </li>
                    <li>
                      You confirm that the funds used for gambling hereunder do
                      not directly or indirectly come from any illegal sources.
                      The User hereby expressly represents and warrants that
                      he/she is not using any proceeds of criminal or illegal
                      activity, which also includes money laundering, and that
                      no transaction with use of the wallet of such User are
                      being used to facilitate a criminal or illegal activity,
                      including, but not limited to, performing money laundering
                      and financing terrorism.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li id="property">
              <h2>Intellectual Property</h2>
              <ol>
                <li>
                  Scgames and its Affiliates shall be the sole owner(s) of the
                  trademark Scgames and the Scgames logo. Any unauthorised use
                  of the Scgames trademark and the Scgames logo may result in a
                  prosecution.
                </li>
                <li>
                  Website scgames.io is the uniform resource locator of Scgames
                  and no unauthorised use may be made of this URL on another
                  website or digital platform without our prior written consent.
                </li>
                <li>
                  Scgames is the owner or the rightful licensee of the rights to
                  the technology, software and business systems used within this
                  Website.
                </li>
                <li>
                  Links to the Website and any of the pages therein may not be
                  included in any other website without the prior written
                  consent of Scgames.
                </li>
                <li>
                  You agree not to use any automatic or manual device to monitor
                  any Scgames web pages or any content therein. Any unauthorised
                  use or reproduction may be prosecuted.
                </li>
              </ol>
            </li>
            <li id="rewards">
              <h2>Rewards and VIP Program</h2>
              <ol>
                <li>
                  Scgames reserves the right to change the terms and conditions
                  and rules for the Rewards Program at any time, e.g., change
                  the parameters for calculations of points. If Scgames suspects
                  that You are engaged in illegal or fraudulent activities when
                  using the Scgames Rewards and VIP Program or in breach with
                  these T&amp;Cs, Scgames may freeze or terminate Your Member
                  Account and/or a related account (e.g. a referred friend) at
                  Scgames's absolute discretion.
                </li>
                <li>
                  The VIP level is personal and cannot be transferred to another
                  User.
                </li>
              </ol>
            </li>
            <li id="complaints">
              <h2>Complaints</h2>
              <ol>
                <li>
                  If You have a complaint, You can email Our customer support
                  via email at support@scgames.io and we will reply to You
                  within 10 days, unless there are external factors causing
                  delays.
                </li>
                <li>
                  Scgames will use its reasonable efforts to resolve a reported
                  matter promptly.
                </li>
                <li>
                  If You have a query with regard to any transaction or the
                  Website, You may also contact Our customer support via email
                  at support@scgames.io with details of the query. We will
                  review any queried or disputed transactions.
                </li>
                <li>
                  For Your protection and to ensure the best possible service to
                  You by Scgames, telephone conversations and other relevant
                  communication between You and Scgames may be recorded and/or
                  monitored.
                </li>
                <li>
                  Scgames will not tolerate derogatory, abusive or violent
                  behavior or statements that are threatening, vulgar,
                  defamatory or otherwise offensive. Should You behave in any
                  such manner towards Scgames’s employees, (as determined in
                  Scgames’s sole opinion), Scgames reserves the right to suspend
                  and/or close Your Member Account and stop all communication
                  and/or replies from Scgames, and/or take any further measures
                  as may be deemed appropriate, including reporting to relevant
                  local authorities and law enforcement agencies.
                </li>
              </ol>
            </li>
            <li id="refund">
              <h2>Refund Policy</h2>
              <ol>
                <li>
                  Unless otherwise expressly established hereby, Scgames does
                  not guarantee any reimbursements, returns and refunds and in
                  no case will provide refunds, except when the funds in
                  question have not been used by You as Your stake in the Games.
                </li>
                <li>
                  Should any of the technical breakdowns, errors or problems
                  which took place due to Our fault (hereinafter collectively
                  referred to as the “Technical Issues”) result in any decrease
                  of the balance of Your wallet (Member Account), Scgames may
                  consider Your request for refund.
                </li>
                <li>
                  You agree that Scgames cannot prevent all potential and
                  possible Technical Issues. If You face any Technical Issue
                  while using the Website or the Games, please notify Our
                  customer support via email at support@scgames.io.
                </li>
                <li>
                  In the event that a Technical Issue faced by You results in
                  decrease of the balance of Your wallet (Member Account), You
                  shall communicate Our customer support with the respective
                  request for refund within seven (7) days upon the moment
                  (date) when the respective decrease of the balance of Your
                  wallet (Member Account) took place or You became aware
                  thereof.
                </li>
                <li>
                  For the avoidance of doubt You shall be deemed to be aware of
                  a decrease of the balance of Your wallet (Member Account) at
                  the first time You logged in Your Member Account after the
                  respective decrease of the balance. Any request for refund
                  sent upon expiration of the specified in Clause 7.3 hereof
                  period will be considered by Us, however, We may refuse such a
                  request for refund without explanation at Our sole discretion.
                </li>
                <li>
                  Scgames shall have the right to request You to provide any
                  document and information to make Your KYC check under
                  Sub-Clause 2.1.8 hereof, and additional information aimed at
                  careful and accurate consideration of Your request for refund.
                  You shall provide Us with such documents and additional
                  information (including all available screenshots and other
                  supporting data) within five (5) days upon receipt of Our
                  request.
                </li>
                <li class="base-doc__item base-doc__h6-marker">
                  <h6 class="base-doc__h6 base-doc__inline">
                    Scgames may refuse Your request for refund if:
                  </h6>
                  <ol>
                    <li>
                      Decrease of the balance of Your wallet (Member Account)
                      does not result from Technical Issue.
                    </li>
                    <li>
                      You failed to provide Us with document and information or
                      additional information as per Clause 7.6 hereof.
                    </li>
                    <li>
                      You request for refund of potential or theoretical win.
                    </li>
                    <li>
                      Decrease of the balance of Your wallet (Member Account)
                      results from problems with Your system (i.e., computer or
                      other technical device used by You while using the Website
                      or Games, due to malfunctioning of Your devices through
                      which the Internet connection was provided to You) or
                      problems with a third party system without Our fault.
                    </li>
                    <li>
                      If You were prohibited from using the Website or a Game as
                      set forth by these T&amp;Cs.
                    </li>
                    <li>
                      Timing out as a result of inactivity in Your system.
                    </li>
                  </ol>
                </li>
                <li>
                  Should You submit to Us false or fraudulent information with
                  Your request for refund more than twice time in a row or more
                  than three times in general, Scgames shall have the right to
                  suspend and/or close Your Member Account and stop all
                  communication and/or replies from Scgames, and/or take any
                  further measures as may be deemed appropriate, including
                  reporting to relevant local authorities and law enforcement
                  agencies.
                </li>
              </ol>
            </li>
            <li id="liability">
              <h2>Limitation of Liability</h2>
              <ol>
                <li>
                  You enter and use the Website and participate in the Games at
                  Your own risk. The Website and the Games are provided without
                  any warranty whatsoever, whether express or implied.
                </li>
                <li class="base-doc__item base-doc__h6-marker">
                  <h6 class="base-doc__h6 base-doc__inline">
                    Without prejudice to the generality of the preceding
                    provision, Scgames, its directors, employees, partners,
                    service providers:
                  </h6>
                  <ol>
                    <li>
                      Do not warrant that the software, the Website or the Games
                      is/are fit for their purpose.
                    </li>
                    <li>
                      Do not warrant that the software, the Website or the Games
                      are free from errors.
                    </li>
                    <li>
                      Do not warrant that the Websites and/or Games will be
                      accessible without interruptions.
                    </li>
                    <li>
                      Shall not be liable for any loss, costs, expenses or
                      damages, whether direct, indirect, special, consequential,
                      incidental or otherwise, arising in relation to Your use
                      of the Websites or Your participation in the Games.
                    </li>
                  </ol>
                </li>
                <li>
                  You hereby agree to fully indemnify and hold harmless Scgames,
                  its directors, employees, partners, and service providers for
                  any cost, expense, loss, damages, claims and liabilities
                  howsoever caused that may arise in relation to Your use of the
                  Website or participation in the Games.
                </li>
                <li>
                  Scgames shall not bear any liability related to the operation
                  of any software, the presence of computer viruses or other
                  malicious code elements, dangerous or destructive files, which
                  can spread or in any other way affect the software and
                  hardware while You are using the Website or Scgames services,
                  participating in the Games or accessing any information or
                  downloading any kind of data.
                </li>
              </ol>
            </li>
            <li id="warranty">
              <h2>Disclaimer of Warranties</h2>
              <ol>
                <li>
                  You expressly agree that the Website and the Games are
                  provided "as is", "with all faults" and "as available",
                  without warranty of any kind. Scgames disclaims all
                  warranties, express, implied, statutory, or otherwise,
                  including, but not limited to, implied warranties of fitness
                  for a particular purpose, merchantability, title, quality, and
                  non-infringement.
                </li>
                <li>
                  Scgames does not guarantee that the Website and any services
                  of Scgames will be available at all times, in all countries
                  and/or all geographic locations, or at any given time, or that
                  Scgames will continue to offer its services for any particular
                  length of time (unless We expressly say otherwise in writing).
                </li>
                <li>
                  Scgames does not guarantee, represent, or warrant that Your
                  use of the Website will be uninterrupted, timely, secure, or
                  error-free. Scgames does not warrant that the results that may
                  be obtained from the use of the Website will be accurate or
                  reliable. Scgames makes no representations, and expressly
                  disclaims any warranties, of any kind, regarding the accuracy,
                  completeness, reliability, appropriateness, and timeliness of
                  any Game and content available on or through the Website.
                </li>
                <li>
                  Scgames is not responsible for any damage to Your mobile,
                  tablet, laptop, or desktop device, computer system, other
                  hardware or software, or for any loss of or damage to data
                  that may result from use of the Website or the Games by You.
                </li>
              </ol>
            </li>
            <li id="reporting">
              <h2>Anti-money Laundering Reporting</h2>
              <ol>
                <li>
                  If You become aware of any suspicious activity relating to any
                  of the Games on the Website, You must report this to Scgames
                  immediately.
                </li>
              </ol>
            </li>
            <li id="penalties">
              <h2>Breaches, Penalties and Termination</h2>
              <ol>
                <li>
                  If You breach any provision of these T&amp;Cs or if Scgames
                  has a reasonable ground to suspect that You have breached
                  them, Scgames reserves the right not to open, suspend, close
                  Your Member Account, withhold any money in Your Member Account
                  (including any deposit) and use such funds on Your Member
                  Account to settle any damages incurred in this respect.
                </li>
                <li>
                  If Scgames suspects that You are engaged in illegal or
                  fraudulent activities when using the Website or the Games; or
                  in breach of this Agreement; or that You are having problems
                  with creditors or otherwise detrimental to Our business, We
                  may freeze or terminate Your Member Account or cancel any
                  stakes at Our absolute discretion. Without prejudice to the
                  generality of the foregoing, in order to mitigate and prevent
                  illegal or fraudulent activity through use of the Website and
                  Scgames services, We may, at our discretion, request that You
                  cease to and/or refrain from making use of a VPN or Proxy
                  while using the Website. If suspicious, illegal or fraudulent
                  activity is detected on an account while a VPN or Proxy is or
                  was in use, we shall have the right to block the said account
                  and funds held thereon, based on our assessment of the
                  activity in question.
                </li>
                <li>
                  You acknowledge that Scgames at its own discretion may decide
                  to suspend or permanently bar You from using the Website if
                  You have violated these T&amp;Cs, without any right of
                  compensation or other for You.
                </li>
              </ol>
            </li>
            <li id="gaming">
              <h2>General Game Rules</h2>
              <ol>
                <li>
                  Scgames retains the right to stop offering You any Game.
                </li>
                <li>
                  Each bet, stake and wage made by You will be governed by the
                  version of the T&amp;Cs and rules of the respective Game which
                  are valid at the time of the bet, stake or wage being
                  accepted.
                </li>
                <li>
                  A bet, stake or wage shall be deemed to have been accepted as
                  soon as it is received on the Scgames system and validated and
                  processed.
                </li>
                <li>
                  Scgames retains the right to decline to accept bets, stakes
                  and wages on any Game without providing any reason.
                </li>
                <li>
                  A bet, stake or wage which has been placed and confirmed
                  cannot be amended, withdrawn or cancelled by You.
                </li>
                <li>
                  When You place a bet, stake or wage You acknowledge that You
                  have read and understood in full all of the T&amp;Cs and rules
                  of the respective Game available on the Website.
                </li>
                <li>
                  Winnings will be paid into Your wallet (Member Account) after
                  the final result is confirmed.
                </li>
                <li>
                  Communication errors do occasionally happen. Bets, stakes and
                  wages are confirmed only when received on Blockchain. Should
                  there be a break in communication after You offer a bet, stake
                  or wage and it has been received on Scgames servers it will
                  still be considered to be valid. If Your bet, stake or wage
                  offer is not received at blockchain then it will not stand and
                  the bet, stake or wage amount will not have been taken from
                  Your Wallet.
                </li>
                <li>
                  If You disconnect Your session with Scgames during an active
                  Game, the amount of the stake will be returned to the Your
                  Game account. When applicable, amounts of un-wagered stakes
                  will be returned to Your Game account and confirmed bets will
                  be processed and winning will be credited to the Your Game
                  account.
                </li>
                <li>
                  Scgames endeavours to make no mistakes when quoting paytables;
                  if any mistake is inadvertently done, Scgames reserves the
                  right to modify the payout ratios even once the event has
                  finished (when applicable).
                </li>
                <li>
                  If for any reason, Scgames incorrectly credits Your Wallet, it
                  is Your responsibility to immediately notify Scgames of the
                  error, and repay Scgames the incorrect balance.
                </li>
                <li>
                  In the event of software or random number generator
                  malfunctioning, the bet will be considered null and void and
                  the amount of the stake will be returned to You.
                </li>
                <li>
                  For detailed Game rules please refer to the rules attached to
                  each of the Games while Your session.
                </li>
              </ol>
            </li>
            <li id="misc">
              <h2>Miscellaneous</h2>
              <ol>
                <!-- <li>
          Applicable Law and Dispute Resolution. Scgames is operated under
          the laws and regulations of Curacao. You irrevocably agree that
          the courts of Curacao will have the exclusive jurisdiction over
          any dispute or claim relating to these T&amp;Cs and the Agreement.
        </li> -->
                <li>
                  Severability. If any part of this Agreement is held to be
                  invalid by law, the remaining parts of this Agreement shall
                  remain in force unless the deletion of such invalid part
                  results in a fundamental change to the rights or obligations
                  of a party.
                </li>
                <li>
                  Entire Agreement. This Agreement constitutes the entire
                  agreement between You and Scgames with respect to the Website,
                  save for the case of fraud, it supersedes all prior or
                  contemporaneous communications and proposals, whether
                  electronic, oral or written, between You and Scgames with
                  respect to the Website. A printed version of this Agreement
                  and of any notice given in electronic form shall be admissible
                  in judicial or administrative proceedings based upon or
                  relating to this Agreement to the same extent and subject to
                  the same conditions as other business documents and records
                  originally generated and maintained in printed form.
                </li>
                <li>
                  Assignment. Scgames reserves the right to assign or otherwise
                  lawfully transfer this Agreement. You will not have the right
                  to assign or otherwise transfer this Agreement without prior
                  written consent of Scgames. You shall not assign the Agreement
                  with Scgames in any manner without the prior written consent
                  of Scgames, and any purported assignment in contravention of
                  this Clause 15.4 shall be null and void. Scgames may assign
                  the Agreement with You to any of its Affiliates or to a third
                  party. By accepting these T&amp;Cs and entering the Agreement,
                  You irrevocably consent for the above mentioned assignment
                  and/or transfer, including for the transfer of Your personal
                  data and other information, as well as for the transfer of any
                  funds on Your wallet (Member Account) held by Scgames in the
                  course of such assignment.
                </li>
                <li>
                  External Sites. Scgames makes no representations whatsoever
                  about any external or third-party website You may access
                  through the Website. Occasionally, Our Website may provide
                  references or links to other websites ("External Websites").
                  We do not control these External Websites or third-party
                  websites or any of the content contained therein. You agree
                  that We are in no way responsible or liable for the External
                  Websites referenced or linked from the Website, including, but
                  not limited to, website content, policies, failures,
                  promotions, products, opinions, advices, statements, prices,
                  activities and advertisements, services or actions and/or any
                  damages, losses, failures or problems caused by, related to,
                  or arising from those websites. You shall bear all risks
                  associated with the use of such external content and External
                  Websites. External Websites have separate and independent
                  terms of use and related policies. We request that You review
                  the policies, rules, terms and regulations of each External
                  Website that You visit. It is up to You to take precautions to
                  ensure that whatever You select for Your use is free of items
                  such as viruses, worms, Trojan horses and other items of a
                  destructive nature.
                </li>
                <li>
                  Legal Restrictions. The User understands and accepts that
                  online gambling may not be legal in some jurisdictions. We
                  make no representation whatsoever as to the legality of the
                  Website and the Games. The use of the Our services is on the
                  Your sole discretion, option, and risk. Nevertheless, we
                  restrict the use of Scgames to the Users from the Restricted
                  Jurisdictions and/or any other restricted by law country or
                  state. Use of Our services is unauthorized and unlicensed
                  wherever it is unlawful under applicable law. You may not use
                  the Website and the Games at any place or in any manner that
                  would violate any applicable laws and/or regulations. Any
                  offer of any feature, product or service is void where
                  prohibited. Scgames may limit or deny to any person, in its
                  sole discretion, access to the Website, Games, any feature,
                  product or service.
                </li>
                <li>
                  Security and Viruses. Any use of the Internet may be subject
                  to a virus attack and/or communication failure. Scgames shall
                  not bear any liability, whatsoever, for any damage or
                  interruptions caused by computer viruses, spyware, Trojan
                  horses, worms or other malware that may affect Your systems,
                  computer or other equipment, or any phishing, spoofing or
                  other virus attacks. Scgames recommends that You use a
                  reputable and available virus screening and prevention
                  software at all times. You should also apply caution when
                  reviewing text messages and emails purporting to originate
                  from Scgames, as SMS and emails are also vulnerable to
                  phishing and spoofing and additional viruses.
                </li>
                <li>
                  Website Accuracy. Although we intend to provide accurate and
                  timely information on the Website, the latter (including,
                  without limitation, its content) may not always be entirely
                  accurate, complete or current and may also include technical
                  inaccuracies or typographical errors. In an effort to continue
                  to provide You with as complete and accurate information as
                  possible, information may, to the extent permitted by
                  applicable law, be changed or updated from time to time
                  without notice, including without limitation information
                  regarding our policies, products, services and the Games.
                  Accordingly, You should verify all information before relying
                  on it, and all decisions based on information contained on the
                  Website are Your sole responsibility and We shall have no
                  liability for such decisions.
                </li>
                <li>
                  Amendments of T&amp;Cs. Scgames reserves the right to modify
                  these T&amp;Cs and, thus, to amend the Agreement between You
                  and Scgames at any time. Such modification shall take effect
                  immediately upon the new version of these T&amp;Cs is
                  published on the Website. The date of modification thereof
                  will always be specified at the top of these T&amp;Cs (see
                  “Last updated”). Each time You visit the Website You shall
                  check the last date of modification of these T&amp;Cs and read
                  the updated version of these T&amp;Cs carefully in their
                  entirety before You start using the Website, Scgames services,
                  any of the Games. By using the Website, Scgames services, any
                  of the Games upon modification of these T&amp;Cs, You signify
                  that You agree with the updated version of these T&amp;Cs and
                  commit Yourself to abide by them at all times when using the
                  Website, Scgames services, any of the Games. If You do not
                  agree to be bound by the updated version of these T&amp;Cs You
                  may stop using the Website, Scgames services, any of the
                  Games, withdraw all available funds and ask customer support
                  to close Your Member Account.
                </li>
                <li>
                  English Language Controls. These T&amp;Cs are published in a
                  number of languages for information purposes and ease of
                  reference. It is only the English version that is the legal
                  basis of the relationship between You and Scgames and in case
                  of any discrepancy between a non-English version and the
                  English version of these T&amp;C, the English version shall
                  prevail.
                </li>
                <li>
                  Indemnification. You agree to defend, hold harmless and hereby
                  indemnify Scgames and its Affiliates from and against any
                  loss, damage, cost, claim, proceeding, penalty, fine or
                  expense, including legal fees, incurred by or suffered by
                  Scgames and its Affiliates, which arises out of, or relates
                  to, directly or indirectly: (i) Your use of the Website and
                  the Games; (ii) Your failure to fully and timely perform any
                  of Your obligations hereunder; (iii) any of Your
                  representations or warranties made hereunder being, at any
                  time, untrue or incorrect; (iv) Your use of the Website, Games
                  and Your wallet (Member Account) by You or any person who is
                  using Your wallet (Member Account) whether with your
                  knowledge, approval and authorization or without it; (v) any
                  violation by You of any law, rule, regulation, or the rights
                  of any third party.
                </li>
              </ol>
            </li>
          </ul>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    document.body.style.overflow = "hidden";
    return {
      history: window.history,
    };
  },
  methods: {
    closePage() {
      this.history.back();
      document.body.style.removeProperty("overflow");
    },
  },
};
</script>
<style scoped>
.aboutList {
  margin: 0px;
  padding: 0px;
  text-align: left;
  list-style-type: none;
  padding-top: 10px;
}
.aboutList li {
  padding: 15px;
  line-height: 25px;
}
</style>
